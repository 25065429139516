<template>
  <div>
    <sale-header />
    <customer />
    <new-car />
    <payments />
    <b-alert
      variant="warning"
      show
    >
      <div class="alert-body">
        Teslimat Tarihi: {{ moment(itemData.ddate).format('LL') }}
      </div>
    </b-alert>
    <div class="text-center">
      <b-button
        variant="danger"
        class="mr-1"
      >
        Trafik Müşavirine Gönder
      </b-button>
      <b-button
        variant="success"
        class="mr-1"
      >
        Yazdır
      </b-button>
      <b-button
        variant="warning"
        class="mr-1"
      >
        Teslimat Formu
      </b-button>
      <b-button
        variant="info"
      >
        Proforma Fatura
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton, BAlert,
} from 'bootstrap-vue'
import SaleHeader from '@/views/G2Sales/View/Header.vue'
import Customer from '@/views/G2Sales/View/Customer.vue'
import NewCar from '@/views/G2Sales/View/NewCar.vue'
import Payments from '@/views/G2Sales/View/Payments.vue'

export default {
  name: 'G2SaleView',
  components: {
    BButton,
    BAlert,
    SaleHeader,
    Customer,
    NewCar,
    Payments,
  },
  data() {
    return {
      dataLoading: true,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['g2sales/getG2Sale']
    },
    saveStatus() {
      return this.$store.getters['g2sales/getG2SaleSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.interview.submitStatus = false
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('g2sales/g2Sale', this.$route.params.id)
        .then(res => {
          if (res) {
            this.dataLoading = false
          }
        })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      }).then(resoponse => {
        if (resoponse.isConfirmed) {
          this.getData()
          // this.$router.push(`/interviews/view/${this.interview.interview_number}`)
        }
      })
    },
  },
}
</script>
