<template>
  <b-alert
    variant="primary"
    show
  >
    <div class="alert-body">
      <b-row>
        <b-col>
          <div class="font-small-2">
            Satış Danışmanı
          </div>
          <div class="font-weight-bolder font-medium-3">
            {{ itemData.username }}
          </div>
        </b-col>
        <b-col cols="auto">
          <div class="font-small-2">
            Sipariş No
          </div>
          <div class="font-weight-bolder font-medium-3">
            {{ itemData.order_number }}
          </div>
        </b-col>
      </b-row>
    </div>
  </b-alert>
</template>

<script>
import { BAlert, BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'Header',
  components: {
    BAlert,
    BRow,
    BCol,
  },
  computed: {
    itemData() {
      return this.$store.getters['g2sales/getG2Sale']
    },
  },
}
</script>
