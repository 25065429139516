<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Müşteri Bilgileri</b-card-title>
      <b-form-checkbox
        v-model="isDetail"
        switch
        inline
      >
        Tüm Bilgileri Göster
      </b-form-checkbox>
    </b-card-header>
    <b-table-simple>
      <b-thead>
        <b-tr v-if="isDetail">
          <b-th>
            T.C. Kimlik No
          </b-th>
          <b-td>{{ itemData.tckn }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="width-200">
            Adı Soyadı
          </b-th>
          <b-td>{{ itemData.customer_name }}</b-td>
        </b-tr>
        <b-tr v-if="isDetail">
          <b-th>Baba Adı</b-th>
          <b-td>{{ itemData.father_name }}</b-td>
        </b-tr>
        <b-tr v-if="isDetail">
          <b-th>Anne Adı</b-th>
          <b-td>{{ itemData.mother_name }}</b-td>
        </b-tr>
        <b-tr v-if="isDetail">
          <b-th>Doğum Tarihi</b-th>
          <b-td>{{ itemData.birthday ? moment(itemData.birthday).format('DD.MM.YYYY') : null }}</b-td>
        </b-tr>
        <b-tr v-if="isDetail">
          <b-th>Doğum Yeri</b-th>
          <b-td>{{ itemData.place_birth }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Cep Telefonu</b-th>
          <b-td>{{ itemData.phone }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>E-Posta</b-th>
          <b-td>{{ itemData.email }}</b-td>
        </b-tr>
        <b-tr v-if="isDetail">
          <b-th>Meslek</b-th>
          <b-td>{{ itemData.job }}</b-td>
        </b-tr>
        <b-tr v-if="isDetail">
          <b-th>Adres</b-th>
          <b-td>
            <div>{{ itemData.address }}</div>
            <div>{{ itemData.city }} / {{ itemData.district }} / {{ itemData.region }} / {{ itemData.neighborhood }}</div>
          </b-td>
        </b-tr>
      </b-thead>
    </b-table-simple>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BTableSimple, BTd, BTh, BThead, BTr, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'Customer',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BFormCheckbox,
  },
  data() {
    return {
      isDetail: false,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['g2sales/getG2Sale']
    },
  },
}
</script>
