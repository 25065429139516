<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Yeni Araç Bilgileri</b-card-title>
    </b-card-header>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th class="align-middle width-200">
            Araç Modeli
          </b-th>
          <b-td>
            <div>{{ itemData.brand }} {{ itemData.model }}</div>
            <div class="font-small-2 text-warning">
              {{ itemData.hardware }}
            </div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Model Açıklama
          </b-th>
          <b-td>{{ itemData.model_detail? itemData.model_detail : '-' }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Yakıt - Vites
          </b-th>
          <b-td>{{ itemData.fuel }} {{ itemData.gear }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Model Yılı
          </b-th>
          <b-td>{{ itemData.myear }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Kilometre
          </b-th>
          <b-td>{{ itemData.km }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Şase
          </b-th>
          <b-td>{{ itemData.chasis }}</b-td>
        </b-tr>
        <b-tr>
          <b-th
            class="align-middle"
          >
            Plaka
          </b-th>
          <b-td
            class="font-weight-bolder"
          >
            {{ itemData.license_plate ? itemData.license_plate : '-' }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Satış Fiyatı
          </b-th>
          <b-td class="text-danger font-weight-bolder">
            {{ itemData.sale_price | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Plaka Değişimi Olacak mı?
          </b-th>
          <b-td>
            {{ itemData.license_plate_change ? 'Evet' : 'Hayır' }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Satış Tipi
          </b-th>
          <b-td>
            {{ itemData.sale_type }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Stok Tipi
          </b-th>
          <b-td>
            {{ itemData.stock_type }}
          </b-td>
        </b-tr>
      </b-thead>
    </b-table-simple>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle,
  BTableSimple, BTd, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'Customer',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
  },
  computed: {
    itemData() {
      return this.$store.getters['g2sales/getG2Sale']
    },
  },
}
</script>
